module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-helmet-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://lite.sportrelief.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"modifyUrlPrefix":{"/":"/"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Sport Relief Lite","short_name":"Sport Relief","start_url":"/","background_color":"#fff","theme_color":"#E52630","display":"standalone","icon":"/tmp/build/620db633/repository/static/assets/SR.svg"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-M5TVCTN","includeInDevelopment":true,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby","siteCategory":"SR","pageCategory":"","user":{"userEmail":"N"}}}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
